<template>
  <b-container fluid="xl">
    <vue-element-loading :active="isloading" spinner="ring" background-color="rgba(255,255,255,0.6)" size="400" color="#36F"/>
    <div v-show="items.length">
      <b-row cols="1" cols-sm="1" cols-md="2" cols-lg="3">
        <b-col v-for="(item, index) in items" :key="index">
      <b-card class="xnshadow mb-5" :body-class="item.connected?'':'offlin'" header-tag="header" :header-class="item.stp?'text-warning':'text-white'" footer-tag="footer" footer-class="text-right" :header-bg-variant="item.connected?'success':'danger'">
        <template v-slot:header>
          <p class="mb-0"><span class="ml-3 mr-4" v-b-tooltip.hover :title="item.mac">#{{item.chargerid}}</span>
          <img :src="item.sigid" style="width:20px;height:20px;" :alt="item.sigmsg" :title="item.sigmsg"/>
          </p>
        </template>
        <b-card-text>
          <b-row cols="2" class="mb-2">
            <div><span class="btn-sm">{{item.connected?$t('message.DataTime'):$t('message.OffTime')}}</span><span style="font-size:small;" @click="gotobeeptest(item)">{{item.connected?item.beep:item.ofline}}</span></div>
            <div><b-link href class="text-decoration-none btn-sm lnk" @click="gotopow(item)">{{'PowUse'|trans}}</b-link><span style="font-size:small;">{{item.pow}}</span></div>
            <div><span class="btn-sm" :class="item.ac?'text-danger':''">{{acs[item.ac]}}</span><span style="font-size:small;">{{localtime}}</span></div>
            <div><span class="btn-sm"><span @click="docmd(17,item)">{{'Dev'|trans}}</span><span @click="docmd(19,item)">{{'Tempr'|trans}}</span></span><span style="font-size:small;">{{item.tp[0]}}/{{item.tp[1]}}/{{item.tp[2]}}</span></div>
            <div class="text-secondary"><span class="btn-sm">{{'Vol&Cur'|trans}}</span><span style="font-size:small;"><span @click="changepvnv(index)">{{item.pvv}}</span>/{{item.pii}}</span></div>
            <div class="text-secondary"><span class="btn-sm"><span @click="allsw(15,item)">{{'STOP'|trans}}</span><span @click="allsw(16,item)">{{'OPEN'|trans}}</span></span><span style="font-size:small;">{{item.stp?'DN':'Fn'}}/{{item.ta?'OP':'Fn'}}</span></div>
          </b-row>
          <b-row cols="2" class="mb-2" style="font-size:small;">
            <div class="pt-2 pb-1">
             <b-col style="padding-left:0px;padding-right:0px;" class="mb-2">
                <b-link href @click="toggleimax(0,item)" class="text-decoration-none btn-sm lnk" :disabled="item.connected==0"
                v-b-tooltip.hover :title="item.imax[0]?('MaxCurrency'+item.imax[0]+'A,Click To Disable'):('Click to set default')">
                {{item.imax[0]?$t('message.LeftEnergy'):$t('message.Disabled(left)')}}
                </b-link>
                <span @click="resetpow(0,item)">{{item.pw[0]}}</span></b-col>
              <b-col class="mb-2" :class="item.imax[0]?'':'invalid'">
              <b-link href @click="togglesw(0,item)" :class="sts[item.sw[0]]" class="text-decoration-none mr-2 lnk">
                  <span>{{'Switch'|trans}}: </span>
                  <b-icon :icon="sws[item.sw[0]]" font-scale="1.5" :variant="sts[item.sw[0]]"></b-icon>
              </b-link>
              <b-link href @click="showusr(0,item)" class="text-decoration-none mr-1 lnk" :disabled="item.uid[0]==''">
                  <span>{{'User'|trans}}: </span>
                  <b-iconstack font-scale="1.5" v-if="item.uid[0]">
                    <b-icon stacked icon="person-fill" variant="info"></b-icon>
                    <b-icon stacked icon="circle" variant="info"></b-icon>
                  </b-iconstack>
                  <b-iconstack font-scale="1.5" v-else>
                    <b-icon stacked icon="person" variant="grey"></b-icon>
                    <b-icon stacked icon="circle" variant="grey"></b-icon>
                  </b-iconstack>
              </b-link>
              </b-col>
              <b-col style="padding-left:0px;padding-right:0px;" class="mb-2" :class="item.imax[0]?'':'invalid'">
              <span @click="pwmen(3,0,index)" class="btn-sm">{{'CurrState'|trans}}</span><span>
              <span @click="pwmen(2,0,index)">{{item.pi[0]}}</span>
              <span @click="pwmen(1,0,index)" class="ml-1 greyco">{{item.st[0]}}&nbsp;&nbsp;</span></span>
              <span @click="pwmen(0,0,index)">{{imax[index][0]}}</span>
              </b-col>
              <b-col style="padding-left:0px;padding-right:0px;" class="btn-sm" :class="item.imax[0]?'':'invalid'"><span class="btn-sm">{{'ProtoVol'|trans}}</span><span>{{item.cp[0]}}<span class="ml-1 greyco">{{item.cz[0]}}</span></span></b-col>
            </div>
            <div class="pt-2 pb-1">
              <b-col style="padding-left:0px;padding-right:0px;" class="mb-2">
                <b-link href @click="toggleimax(1,item)" class="text-decoration-none btn-sm lnk" :disabled="item.connected==0"
                v-b-tooltip.hover :title="item.imax[1]?('MaxCurrency'+item.imax[1]+'A,Click to Disable'):('Click to set default')">
                {{item.imax[1]?$t('message.RightEnergy'):$t('message.Disabled(right)')}}
                </b-link>
                <span @click="resetpow(1,item)">{{item.pw[1]}}</span></b-col>
              <b-col class="mb-2" :class="item.imax[1]?'':'invalid'">
              <b-link href @click="togglesw(1,item)" :class="sts[item.sw[1]]" class="text-decoration-none mr-2 lnk">
                  <span>{{'Switch'|trans}}: </span>
                  <b-icon :icon="sws[item.sw[1]]" font-scale="1.5" :variant="sts[item.sw[1]]"></b-icon>
              </b-link>
              <b-link href @click="showusr(1,item)" class="text-decoration-none mr-1 lnk" :disabled="item.uid[1]==''">
                  <span>{{'User'|trans}}: </span>
                  <b-iconstack font-scale="1.5" v-if="item.uid[1]">
                    <b-icon stacked icon="person-fill" variant="info"></b-icon>
                    <b-icon stacked icon="circle" variant="info"></b-icon>
                  </b-iconstack>
                  <b-iconstack font-scale="1.5" v-else>
                    <b-icon stacked icon="person" variant="grey"></b-icon>
                    <b-icon stacked icon="circle" variant="grey"></b-icon>
                  </b-iconstack>
              </b-link>
              </b-col>
              <b-col style="padding-left:0px;padding-right:0px;" class="mb-2" :class="item.imax[1]?'':'invalid'">
              <span @click="pwmen(3,1,index)" class="btn-sm">{{'CurrState'|trans}}</span><span>
              <span @click="pwmen(2,1,index)">{{item.pi[1]}}</span>
              <span @click="pwmen(1,1,index)" class="ml-1 greyco">{{item.st[1]}}&nbsp;&nbsp;</span></span>
              <span @click="pwmen(0,1,index)">{{imax[index][1]}}</span>
              </b-col>
              <b-col style="padding-left:0px;padding-right:0px;" :class="item.imax[1]?'':'invalid'"><span class="btn-sm">{{'ProtoVol'|trans}}</span><span>{{item.cp[1]}}</span><span class="ml-1 greyco">{{item.cz[1]}}</span></b-col>
            </div>
          </b-row>
          <b-row style="font-size:small;" class="ml-0">
            <div>@{{item.act}} #{{item.pon}} {{resetflags[item.rsf]}}, @{{item.pot}} #{{item.cot}} {{'reConn'|trans}}</div>
          </b-row>
        </b-card-text>
        <template v-slot:footer>
          <b-button size="sm" variant="outline-primary" class="mr-2"
          :to="{path:'admbill1',query:{chargerid:item.chargerid,tm:new Date().getTime()}}">{{'btn_History'|trans}}</b-button>
          <b-button size="sm" variant="outline-primary" class="mr-2" :disabled="item.connected==0" @click="docmd(11,item)">{{'btn_ACT'|trans}}</b-button>
          <b-button size="sm" variant="outline-primary" class="mr-2" :disabled="item.connected==0" @click="docmd(0,item)">{{'btn_BEP'|trans}}</b-button>
          <b-button size="sm" variant="outline-primary" :disabled="item.connected==0" @click="docmd(1,item)">{{'btn_RBT'|trans}}</b-button>
        </template>
      </b-card>
        </b-col>
      </b-row>
    </div>
    <div v-show="items.length==0">
      <b-card class="xnshadow mb-5" header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h4 class="mb-0"><b-icon icon="phone"></b-icon> No charger device found</h4>
        </template>
        <b-card-text>
          <p>
            buy chargers then shows here<br/>
           </p>
        </b-card-text>
      </b-card>
    </div>
    <div v-show="maskenabled" class="fullmask" @click="closemask">
      <div class="navbar-fixed-top alert-danger text-left pt-2 pb-2" :show="alertmsg" @click="closemask">{{alertmsg}}</div>
      <div class="imgshow xnshadow">
        <img :src="bigheadimgurl" style="max-width:100vw;max-height:50vh"/>
      </div>
    </div>
    <b-form inline class="mb-4">
      <b-button-group class="mr-4">
        <b-button variant="success" @click="fetchprev" :disabled="pageid==0">{{'btn_prevpage'|trans}}</b-button>
        <b-button v-if="pageid>0" variant="outline-success" :disabled="true" >{{pageid+1}}</b-button>
        <b-button variant="success" @click="fetchNext" :disabled="pageid==newpagestart.length">{{'btn_nextpage'|trans}}</b-button>
      </b-button-group>
      <b-radio-group buttons class="mr-4" v-model="online" @change="uchange" button-variant="outline-primary">
        <b-form-radio value="0">{{'opt_all'|trans}}</b-form-radio>
        <b-form-radio value="1">{{'opt_online'|trans}}</b-form-radio>
        <b-form-radio value="2">{{'opt_ofline'|trans}}</b-form-radio>
      </b-radio-group>
    </b-form>
    <b-modal v-model="modalshow" no-close-on-backdrop no-close-on-esc hide-header ok-only :ok-title="$t('message.btn_ok')">{{modalmsg}}</b-modal>
  </b-container>
</template>

<script>
  let reloades = 0;
  export default {
    name: 'admdevices1',
    created() {
      this.gettime();
    },
    mounted() {
      this.isloading = true;
      this.fetchData();
    },
    data() {
      return {
        isloading: false,
        modalshow:false,
        modalmsg: '',
        maskenabled: false,
        bigheadimgurl: '',
        alertmsg: '',
        online:0,
        resetflags:[this.$t('message.ref0'),this.$t('message.ref1'),this.$t('message.ref2'),this.$t('message.ref3'),this.$t('message.ref4'),this.$t('message.ref5'),this.$t('message.ref6'),this.$t('message.ref7')],
        acs: [this.$t('message.localtime'), this.$t('message.gnderror')],
        sts: ['secondary', 'warning', 'grey'],
        sws: ['toggle-off','toggle-on','toggle-off'],
        admnodft:false,
        localtime:'00:00:00',
        facemoney:'',
        cardcnts:'',
        expdays:'',
        items: [],
        pageid:0,
        imax: [0,0],
        newpagestart:[]
      };
    },
    methods: {
      gettime(){
        let nowtm = new Date();
        let hour = nowtm.getHours();
        let minu = nowtm.getMinutes();
        let secd = nowtm.getSeconds();
        if (hour<10) hour = '0'+hour;
        if (minu<10) minu = '0'+minu;
        if (secd<10) secd = '0'+secd;
        this.localtime = hour+':'+minu+':'+secd;
        if (this.$route.fullPath.indexOf('admdevices1')>0) setTimeout(this.gettime, 900);
      },
      fetchprev() {
        this.isloading = true;
        this.pageid = this.pageid-1;
      },
      fetchPage(page) {
        this.isloading = true;
        this.pageid = page;
      },
      fetchNext() {
        this.isloading = true;
        this.pageid = this.pageid+1;
      },
      uchange($event) {
        this.isloading = true;
        this.online = $event;
        this.pageid = 0;
        this.newpagestart = [];
      },
      changepvnv(idx) {
        this.items[idx].pvv = this.items[idx].pnv;
      },
      async togglesw(id,itm) {
        reloades = 0;
        let theapi;
        let token = localStorage.getItem('token');
        if (itm.connected) {
          let val = itm.sw[id]*100+id;
          theapi = 'token='+token+'&mac='+itm.mac+'&cmd=14&val='+val;
          if (itm.cp[id]>100 && itm.sw[id]==0) {
            theapi = theapi + '&uid=' + itm.uid[id];
          }
        } else {
          theapi = 'token='+token+'&mac='+itm.mac+'&cmd=21&val='+id;
        }
        await this.axios.post('/admdocmd?tm='+new Date().getTime(), theapi);
      },
      pwmen(isup,gunid,idx) {
        let user = JSON.parse(localStorage.getItem('user'));
        if (!user.isadmin) return;
        let token = localStorage.getItem('token');
        let mymax = this.imax[idx];
        let val = mymax[gunid];
        if (isup==1) {
            val = val + 1;
        } else if (isup==0){
            val = val - 1;
        } else {
            val = 6;
        }
        if (val<33 && val>5) {
            if (isup==2) val = 0;
            if (isup==3) val = 999;
            mymax[gunid] = val;
            this.imax[idx] = mymax;
            let mac = this.items[idx].mac;
            let theapi = '/docmd?tm='+new Date().getTime()+'&token='+token+'&mac='+mac+'&cmd=23&gun='+gunid+'&val='+val;
            this.axios.get(theapi).then();
        }
      },
      async toggleimax(id,itm) {
        reloades = 0;
        let sw = itm.sw[id];
        if (sw==1) {
            this.modalmsg = 'Port is working, can not disabled!';
            this.modalshow = true;
        } else {
            if (sw==2) {
              let token = localStorage.getItem('token');
              let theapi = 'token='+token+'&mac='+itm.mac+'&cmd=12&val='+id;
              await this.axios.post('/admdocmd?tm='+new Date().getTime(), theapi);
            } else {
              if (confirm('Do you really want to disable this port?')) {
                let token = localStorage.getItem('token');
                let theapi = 'token='+token+'&mac='+itm.mac+'&cmd=13&val='+id;
                await this.axios.post('/admdocmd?tm='+new Date().getTime(), theapi);
              }
            }
        }
      },
      closemask() {
        this.maskenabled = false;
        this.alertmsg = '';
      },
      async showusr(uid,itm) {
        reloades = 0;
        let cid = itm.chargerid;
        let pow = itm.pow;
        let lotoken = localStorage.getItem('token');
        let theapi = 'token='+lotoken+'&uid='+uid+'&cid='+cid;
        let axresp = await this.axios.post('/admshowusr?tm='+new Date().getTime(), theapi);
        let powuse = pow-axresp.data.powstart;
        if (powuse<0) powuse = 0;
        powuse = (powuse/1000).toFixed(3);
        this.alertmsg = axresp.data.nickname+'['+uid+']'+'['+axresp.data.chofflowi+']'+axresp.data.msg+',PowUSED:'+powuse;
        this.maskenabled = true;
      },
      async funagreepow(tp,id) {
        let token = localStorage.getItem('token');
        var theapi = 'token='+token+'&tp='+tp+'&id='+id;
        let axresp = await this.axios.post('/admpowsec?tm='+new Date().getTime(), theapi);
        if (axresp.status==200) {
          var data = axresp.data;
          if (data.rc>0 && data.sec) {
            this.$router.push({name:'power',params:{tp:tp,id:id,sec:data.sec}});
          }
        }
      },
      gotopow(itm) {
        reloades = 0;
        var agree1 = parseInt(localStorage.getItem('agree1')) || 0;
        if (agree1 < 10) {
          var msg = 'Power usage function can be shared and visit without authorize, do you really want to open share page?';
          if (confirm(msg)) {
            localStorage.setItem('agree1',''+(agree1+1));
            this.funagreepow(1, itm.chargerid);
          }
        } else {
          this.funagreepow(1, itm.chargerid);
        }
      },
      gotobeeptest(itm) {
        if (itm.connected) {
          this.$router.push({name:'beeptest',params:{mac:itm.mac}});
        }
      },
      async docmd(id,itm) {
        reloades = 0;
        let token = localStorage.getItem('token');
        let theapi = 'token='+token+'&mac='+itm.mac+'&cmd='+id;
        await this.axios.post('/admdocmd?tm='+new Date().getTime(), theapi);
      },
      async shareme() {
        let token = localStorage.getItem('token');
        let theapi = 'token='+token;
        let axresp = await this.axios.post('/admgetsharesec?tm='+new Date().getTime(), theapi);
        if (axresp.data.rc>0) {
          this.$router.push({name:'monitr1',params:{app:axresp.data.app,sec:axresp.data.sec}});
        } else {
          this.modalmsg = axresp.data.rm;
          this.modalshow = true;
        }
      },
      async allsw(id,itm) {
        reloades = 0;
        let token = localStorage.getItem('token');
        let theapi = 'token='+token+'&mac='+itm.mac+'&cmd='+id;
        await this.axios.post('/admdocmd?tm='+new Date().getTime(), theapi);
      },
      resetpow(id,itm) {
        reloades = 0;
        let user = JSON.parse(localStorage.getItem('user'));
        if (!user.isadmin) return;
        var msg = 'This function is used to reset power usage, confirm to do this?';
        if (confirm(msg)) {
          if (id==0) {
            this.docmd(6,itm);
          } else {
            this.docmd(7,itm);
          }
        }
      },
      async fetchData() {
        let w = window.innerWidth;
        let h = window.innerHeight;
        let lotoken = localStorage.getItem('token');
        let params = 'token='+lotoken+'&online='+this.online+'&w='+w+'&h='+h+'&r='+reloades;
        if (this.$route.query.cidstart) {
          params = params + '&cidstart='+this.$route.query.cidstart+'&cidend='+this.$route.query.cidend;
        }
        if (this.pageid>0 && this.newpagestart[this.pageid-1]) {
          params = params + '&last='+encodeURIComponent(JSON.stringify(this.newpagestart[this.pageid-1]));
        }
        let axresp = await this.axios.post('/admdevices1?tm='+new Date().getTime(), params);
        this.isloading = false;
        if (axresp.status==200) {
          let axdata = axresp.data;
          this.items = axdata.Items;
          if (axdata.LastEvaluatedKey) {
            if (this.newpagestart.length<=this.pageid) {
              this.newpagestart.push(axdata.LastEvaluatedKey);
            } else {
              this.newpagestart[this.pageid] = axdata.LastEvaluatedKey;
            }
          }
        } else {
          this.items = [];
        }
        if (this.$route.fullPath.indexOf('admdevices1')>0 && reloades<10000) {
          let reloadms = 1000;
          if (this.items.length<4) {
            reloadms = 500;
          } else {
            if (reloades<100) {
              reloadms = 800;
            } else if (reloades<500) {
              reloadms = 1000;
            } else if (reloades<1000) {
              reloadms = 2000;
            } else {
              reloadms = 3000;
            }
          }
          setTimeout(this.fetchData, reloadms);
          reloades++;
        }
      },
    }
  }
</script>
